import './App.css';
import Login from './Login'

function App() {
  return (
    <main className='App'>
      <Login />
    </main>
  );
}

export default App;
